import images from './images';

const wines = [
  {
    title: 'Briyani Dam',
    price: '$56',
    tags: 'JH | Plate',
  },
  {
    title: 'Nasi Dagang',
    price: '$30',
    tags: 'KN | Plate',
  },
  {
    title: 'Laksam Kelantan',
    price: '$20',
    tags: 'KN | Plate',
  },
  {
    title: 'Char Kueh Teow',
    price: '$21',
    tags: 'PN | Plate',
  },
  {
    title: 'Satay Malaysia',
    price: '$26',
    tags: 'MS | Plate',
  },
];

const cocktails = [
  {
    title: 'Cendol Asli',
    price: '$20',
    tags: 'Chendol | Kacang Merah | Tapai |Bowl',
  },
  {
    title: "Ais Batu Campur",
    price: '$16',
    tags: 'Jagung | Vanilla | Kacang',
  },
  {
    title: 'Air Tuak Nipah',
    price: '$10',
    tags: 'Chilled | Neat | Iced',
  },
  {
    title: 'Teh Tarik Chef Danial',
    price: '$15',
    tags: 'Chilled | Neat | Iced',
  },
  {
    title: 'Coconut Shake',
    price: '$26',
    tags: 'vanilla | Dark Chocolate | Grass Jelly | Orange garnish',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Halal Food Fest',
    subtitle: 'Best Local Food Kelantan Chapter',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Best Young Chef/ Enterpreneur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Top 5 Charity Participant.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Reviewed by Malaysian Fashion Association',
  },
];

export default { wines, cocktails, awards };
